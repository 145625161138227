:host {
	margin-block-start: .5rem;
	margin-inline: auto;
	position: relative;
}

img {
	border-radius: .5rem;
	max-inline-size: 100%;
	block-size: auto;
	object-fit: cover;
}

div {
	display: flex;
	font-size: .8rem;
	position: absolute;
	top: 0;
	margin: .5rem;
	background: hsl(var(--color-surface-hsl) / .5);
	backdrop-filter: blur(2px);
	border-radius: .5rem;
	padding-inline: .5rem;
	padding-block: .25rem;
}

.material-symbols-outlined {
	display: inline-flex;
	align-items: center;
	margin-inline-end: .375rem;
	font-size: 1rem;
}
